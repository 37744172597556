<template>
  <div class="waiting-signature">
    <div
      v-if="
        $router.currentRoute.path == '/' + role + '/secretariat/waitingList'
      "
    >
      <v-card class="letter-card">
        <v-row>
          <v-col>
            <h4>در انتظار امضا</h4>
          </v-col>
          <v-col class="pe-2">
            <div class="custom-input">
              <v-text-field
                class="float-end"
                outlined
                dense
                style="width: 330px"
                v-model="search"
                placeholder="شماره نامه را جستجو کنید..."
              >
                <template #prepend-inner>
                  <v-icon> $Magnify </v-icon>
                </template>
                <template v-if="search.length" #append>
                  <v-icon @click="search = ''"> $Close </v-icon>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!canSign && computedLetter.length">
          <v-col>
            <v-icon class="me-1 red--text">$AlertOutline</v-icon>
            <span class="red--text font-weight-bold"
              >کاربر گرامی، دسترسی شما برای امضا نامه وجود ندارد. لذا فقط
              می‌توانید جزئیات نامه‌ها را مشاهده نمایید.</span
            >
          </v-col>
        </v-row>
        <v-card class="text-center pa-10 cards" v-if="!isLoaded">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <circle-4></circle-4>
            <h6 class="mt-6">در حال بارگذاری ...</h6>
          </div>
        </v-card>
        <div v-else>
          <v-card
            class="mt-4 ps-0 pe-0"
            v-if="search && !computedLetter.length"
          >
            <v-row>
              <v-col
                class="text-center text--grey text--darken-1"
                style="font-size: 15px"
              >
                <div class="border-box">
                  <v-icon style="margin-left: 88px">$NoResult</v-icon>
                  <p style="margin-top: 88px" class="mb-0">
                    نتیجه‌ای برای "{{ search }}" یافت نشد.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            class="mt-4 ps-0 pe-0"
            v-if="!search && !computedLetter.length"
          >
            <v-row>
              <v-col
                class="text-center text--grey text--darken-1"
                style="font-size: 16px"
              >
                <div class="border-box">
                  <v-icon style="margin-left: 88px">$NoLetter</v-icon>
                  <p style="margin-top: 88px">نامه‌ای برای نمایش وجود ندارد.</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <div v-if="computedLetter.length" class="custom-table mt-8">
            <b-table
              responsive
              show-empty
              stacked="sm"
              :current-page="currentPage"
              :per-page="perPage"
              :items="computedLetter"
              :fields="fields"
              empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
              empty-text="نامه‌ای برای نمایش وجود ندارد"
              striped
              thead-class="headClass"
              tbody-class="bodyClass"
              :busy="isBusy"
            >
              <template v-slot:cell(index)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  class="pt-lg-3 pt-0"
                >
                  {{ data.index + 1 + perPage * (currentPage - 1) }}
                </div>
              </template>
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  class="pt-lg-3 pt-0"
                >
                  {{ data.value }}
                </div>
              </template>

              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    v-if="canSign"
                    :to="
                      '/' +
                      role +
                      '/secretariat/waitingList/submitSignature/' +
                      data.item.id
                    "
                    height="45"
                    class="blue-mainBtn pa-3"
                    >ثبت امضا و ارسال</v-btn
                  >
                  <v-btn
                    v-else
                    :to="
                      '/' +
                      role +
                      '/secretariat/' +
                      data.item.type +
                      '/letterInfo/' +
                      data.item.id
                    "
                    height="45"
                    class="blue-mainBtn"
                    >مشاهده</v-btn
                  >
                </div>
              </template>
              <div slot="table-busy" class="text-center text-danger my-2">
                <v-progress-circular indeterminate color="#00acc1">
                </v-progress-circular>
              </div>
            </b-table>
          </div>
          <v-row v-if="computedLetter.length" class="mt-8">
            <v-col class="d-flex flex-row">
              <span class="pt-2">تعداد موارد نمایش در هر صفحه</span>
              <div class="blue-number-box me-3 ms-3">
                <v-select
                  dense
                  :menu-props="{top:true, offsetY: true}"
                  outlined
                  v-model="perPage"
                  :items="perPageOptions"
                  @change="currentPage = 1"
                ></v-select>
              </div>
            </v-col>
            <v-col>
              <div
                class="d-flex flex-row justify-content-end blue-custom-pagination"
              >
                <v-btn @click="goToFirst()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                >
                <v-pagination
                  v-model="currentPage"
                  :length="Math.ceil(computedLetter.length / perPage)"
                  :total-visible="5"
                  prev-icon="$ArrowLeft"
                  next-icon="$ArrowRight"
                ></v-pagination>
                <v-btn @click="goToLast()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    Circle4,
  },
  data() {
    return {
      search: "",
      fields: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "operation", label: "عملیات" },
      ],
      isLoaded: false,
      perPageOptions: [10, 15, 20],
      letters: [
        // {
        //   id: 321234567654,
        //   letterNo: "82940ت93900",
        //   title: "1طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "2طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "3طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "4طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "5طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "6طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "7طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
        // {
        //   id: 321234567654,
        //   letterNo: "82939ت93900",
        //   title: "طراحی پست فناوری",
        //   date: "1400/12/02",
        // },
      ],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      canSign: false,
      role: "",
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.letters.length / this.perPage);
    },
    getLetters() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/waitingSigns",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.letters = res.data;
            setTimeout(() => {
              this.isLoaded = true;
            }, 200);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  computed: {
    computedLetter() {
      let filtered;
      filtered = this.letters.filter((x) => x.letterNo.includes(this.search));
      return filtered;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.canSign = JSON.parse(localStorage.getItem("canSign"));
    this.getLetters();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../../assets/fonts/IRANSans(FaNum).ttf");
}
.waiting-signature {
  .letter-card {
    border-radius: 17px;
    padding: 30px 20px 20px 25px;
  }
}
</style>
